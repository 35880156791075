'use strict';

// Include base block
var Block = require('../../../../frontend/src/js/libs/block');

function uniq(a) {
    var seen = {};
    return a.filter(function(item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

// Resolving item price depends on BOM structure: Parent item determines the pricing mode
// where either parent is free or children are free
function resolveParentIdAndChildrenIdsForItemField(field) {
    var formsetRow = $(field).closest('[data-is-formset-row]')[0];

    var parent_item_id = null;
    var child_item_ids = [];

    // If frontend_parent_uuid is set, the row isn't saved yet - it's also a children --> link to parent
    // If frontend_uuid field is set, the row isn't saved yet - it's also a parent as children don't have frontend_uuid set --> link to children
    var frontendParentUUIDField = formsetRow.querySelector('[id$="frontend_parent_uuid"]');
    var frontendUUIDField = formsetRow.querySelector('[id$="frontend_uuid"]');

    // Otherwise linking is based on parent_service_item_assignment or parent_spare_part_assignment --> link to parent
    // find rows whose parent_service_item_assignment or parent_spare_part_assignment link to self --> link to children
    var parentServiceItemAssignmentField = formsetRow.querySelector('[id$="parent_service_item_assignment"]');
    var parentSparePartAssignmentField = formsetRow.querySelector('[id$="parent_spare_part_assignment"]');

    // Frontend UUID based matching

    // is defined as parent - link to children
    if (frontendUUIDField && frontendUUIDField.value) {
        var childrenReferences = Array.prototype.slice.call(document.querySelectorAll('[id$="frontend_parent_uuid"][value="' + frontendUUIDField.value + '"]'));
        child_item_ids = childrenReferences.map(function(ref) {
            // Jump up to row level & then go down to the item
            return $(ref).closest('[data-is-formset-row]')[0].querySelector('[data-is-item-field]').value
        });
    }
    // link to parent - find children
    else if (frontendParentUUIDField && frontendParentUUIDField.value) {
        var parentReference = document.querySelector('[id$="frontend_uuid"][value="' + frontendParentUUIDField.value + '"]');
        parent_item_id = $(parentReference).closest('[data-is-formset-row]')[0].querySelector('[data-is-item-field]').value;
    }
    
    // Parent item assignment based matching

    // Link to parent
    if (
        (parentServiceItemAssignmentField && parentServiceItemAssignmentField.value) ||
        (parentSparePartAssignmentField && parentSparePartAssignmentField.value)
    ) {
        var id = parentServiceItemAssignmentField ? parentServiceItemAssignmentField.value : parentSparePartAssignmentField.value;
        var parentReference = document.querySelector('[id$="-id"][value="' + id + '"]');
        parent_item_id = $(parentReference).closest('[data-is-formset-row]')[0].querySelector('[data-is-item-field]').value;
    // Link to children
    } else {
        var idField = $(field).closest('[data-is-formset-row]')[0].querySelector('[id$="-id"]');
        if (idField && idField.value) {
            var children = Array.prototype.slice.call(document.querySelectorAll('[data-is-parent-assignment-reference][value="' + idField.value + '"]'));
            
            // There's some fuckery going on with parent_service_item_assignment and parent_spare_part_assignment
            // as they're rendered twice. We'll have to wrap the result in uniq to fix the issue.
            child_item_ids = uniq(children.map(function(ref) {
                // Jump up to row level & then go down to the item
                return $(ref).closest('[data-is-formset-row]')[0].querySelector('[data-is-item-field]').value
            }));  
        } 
    }

    return {parent_item_id: parent_item_id, child_item_ids: child_item_ids};
}

// Create new block
module.exports = Block.extend({
    template: '#generic-form-view-wrapper',
    
    init: function () {


        var onItemOptionSelected = function(e, field) {
            var orderId = document.getElementById('invoicing-rows').getAttribute('data-order-id');
            
            // Jump to the next event loop iteration to make sure all other event handlers have been executed
            // the most important one is the one that assigns frontend uuid values
            window.setTimeout(function() {
                var references = resolveParentIdAndChildrenIdsForItemField(field);

                TCApi.call(
                    'invoicing_get_inserted_item_price', {
                        item_id: e.detail.current.value, 
                        order_id: orderId, 
                        parent_item_id: references.parent_item_id, 
                        child_item_ids: references.child_item_ids
                    }, 
                    false
                ).then(function(price) {
                    var row = $(field).closest('[data-is-formset-row]')[0];
                    var modifiedUnitPriceField = row.querySelector('[data-is-modified-unit-price-field]');
                    var value = 0;
                    if (price != null) {
                        value = price.value / 100;
                        row.querySelector('[data-is-total-price-currency]').innerText = price.value_unit;
                    }
                    modifiedUnitPriceField.value = value;
                    $(modifiedUnitPriceField).trigger('input');
                });
            });

        };

        this.registerItemFieldEvents = function() {
            var sparePartFields = Array.prototype.slice.call(document.querySelectorAll('[data-is-spare-part-field]'));
            var serviceItemFields = Array.prototype.slice.call(document.querySelectorAll('[data-is-service-item-field]'));
            var isInvoiceRelevantFields = Array.prototype.slice.call(document.querySelectorAll('[data-is-invoice-relevant-field]'));


            $.each(sparePartFields.concat(serviceItemFields), function(index, field) {
                if (!field.hasAttribute('data-events-registered')) {
                    field.addEventListener('initialOptionSelected', onItemOptionSelected);
                    field.addEventListener('itemSelected', function(e) { onItemOptionSelected(e, field) });
                    field.setAttribute('data-events-registered', '');
                }
                
                // Update currency unit for initial rows
                var row = $(field).closest('[data-is-formset-row]')[0];
                var initialData = JSON.parse(field.getAttribute('data-initial') || 'null');
                // Currency unit is either the one provided in initial data or default currency unit based on order country_location.currency_unit
                row.querySelector('[data-is-total-price-currency]').innerText = initialData ? initialData.data.currency_unit : document.getElementById('invoicing-rows').getAttribute('data-default-currency-unit');
            });

            $.each(isInvoiceRelevantFields, function(index, field) {
                $(field).change(function() {
                    this.recalculateInvoiceTotalPrice();
                }.bind(this));
            }.bind(this));
        }.bind(this);
        
        this.registerItemFieldEvents();
        this.recalculateInvoiceTotalPrice();
        $('input[data-qty-input]').trigger('input');

        this.$el.find('[data-is-formset]').each(function(index, formset) {
            formset.addEventListener('rowInserted', function(e) {
                this.registerEventHooks();
                this.registerItemFieldEvents();
            }.bind(this));
        }.bind(this));

        var updatePricesButton = document.querySelector('#update-prices-button');
        if (updatePricesButton) {
            updatePricesButton.addEventListener('click', this.onUpdatePricesButtonClick);
        }
    },

    recalculateInvoiceTotalPrice: function() {
        var total = 0.0;
        document.querySelectorAll('input[data-total-input]').forEach(function (input) {
            var row = $(input).closest('[data-is-formset-row]')[0];
            var isInvoiceRelevantCheckbox = row.querySelector('[data-is-invoice-relevant-field]');

            var value = parseFloat(input.value);
            // Not invoice relevant = free of charge
            if (!isInvoiceRelevantCheckbox.checked) {
                value = 0.0;
            }

            if (value) {
                total += value
            }
        });
        this.hooks.invoiceTotal.text(this.doRound(total) + ' ' + this.hooks.invoiceTotal.data('currency'));
    },

    setUnitPriceToZeroAndRecalculateTotalPrice: function (e) {
        var $containerRow = $(e.target).parents('.invoice-row-wrapper:first'),
            $priceField = $containerRow.find('input[data-price-input]');

        $priceField.val(0);
        this.recalculateRowTotalPrice(e);
    },

    recalculateRowTotalPrice: function (e) {
        var $containerRow = $(e.target).parents('.invoice-row-wrapper:first'),
            $priceField = $containerRow.find('input[data-price-input]'),
            $qtyField = $containerRow.find('input[data-qty-input]'),
            $discountField = $containerRow.find('input[data-discount-input]'),
            $totalField = $containerRow.find('input[data-total-input]');
        
        var priceVal = parseFloat($priceField.val()),
            qtyVal = parseFloat($qtyField.val()),
            discountVal = parseFloat($discountField.val()),
            totalVal = null;

        if (!isNaN(priceVal) && !isNaN(qtyVal)) {
            totalVal = ((priceVal * 100) * qtyVal) / 100.0;
            
            if (!isNaN(discountVal)) {
                totalVal *= (1.0 - (discountVal / 100));
            }

            $totalField.val(this.doRound(totalVal))
        }

        this.recalculateInvoiceTotalPrice();
    },

    doRound: function(value, precision) {
        if (precision != 0 && !precision) {
            precision = 2;
        }
        var multiplicator = Math.pow(10, precision);
        value = parseFloat((value * multiplicator).toFixed(11));
        return Math.round(value) / multiplicator;
    },

    onUpdatePricesButtonClick: function(event) {
        event.preventDefault();
        var updatePricesButton = event.currentTarget;

        updatePricesButton.querySelector('[data-is-default-icon]').classList.add('hidden');
        updatePricesButton.querySelector('[data-is-loading-icon]').classList.remove('hidden');
        updatePricesButton.disabled = true;
        
        // get order service items and spare part rows
        var sparePartFields = Array.prototype.slice.call(document.querySelectorAll('[data-is-spare-part-field]'));
        var serviceItemFields = Array.prototype.slice.call(document.querySelectorAll('[data-is-service-item-field]'));
        
        // get order id from view
        var orderId = document.getElementById('invoicing-rows').getAttribute('data-order-id');       

        var updatedItems = [];
        // read new prices from TC Api for every invoice item
        var fieldsToUpdate = sparePartFields.concat(serviceItemFields);
        var updatedFieldCount = 0;

        $.each(fieldsToUpdate, function(index, field) {
            var references = resolveParentIdAndChildrenIdsForItemField(field);

            TCApi.call(
                'invoicing_get_inserted_item_price_and_debit_code', { 
                    item_id: field.value, 
                    order_id: orderId,
                    parent_item_id: references.parent_item_id,
                    child_item_ids: references.child_item_ids
                }, 
                false
            ).then(function(data) {
                var price = data.price;
                var debit_code = data.debit_code;

                // get formset row for current order item
                var row = $(field).closest('[data-is-formset-row]')[0];

                // get item unit price field and assign new value for field
                var modifiedUnitPriceField = row.querySelector('[data-is-modified-unit-price-field]');
                var discountField = row.querySelector('[data-discount-input]');
                var newPrice = price.value ? price.value / 100 : 0;
                if (newPrice != modifiedUnitPriceField.value) {
                    modifiedUnitPriceField.value = newPrice;
                    $(modifiedUnitPriceField).trigger('input').css('background-color', '#fff9c4');
                }  

                var debitCodeField = row.querySelector('[data-is-debit-code-field]');
                var newDebitCode = debit_code != null && debit_code.id != null ? debit_code.id : null;
                if (newDebitCode != debitCodeField.value && !(newDebitCode == null && debitCodeField.value == '')) {
                    if (newDebitCode == null) {
                        newDebitCode = '';
                    }

                    debitCodeField.value = newDebitCode;
                    $(debitCodeField).trigger('input').css('background-color', '#fff9c4');
                }

                discountField.value = price.discount;
                $(discountField).trigger('input');

                updatedFieldCount += 1;

                if (updatedFieldCount == fieldsToUpdate.length) {
                    updatePricesButton.querySelector('[data-is-default-icon]').classList.remove('hidden');
                    updatePricesButton.querySelector('[data-is-loading-icon]').classList.add('hidden');
                    updatePricesButton.disabled = false;
                }
            });
        });
    },
});
